import {
  IBenchmarkConfigItemRaw,
  ISettings,
  IStichwortParam,
  BenchmarkParts,
  IBenchmarkConfigItem,
  IBenchmarkConfigTrendType,
  BenchmarkConfigProLevelType,
  BenchmarkConfigUnitType,
} from '../../../types';
import { benchmarkConfigHKP } from './benchmark.config.hkp';
import { benchmarkConfigLeistungen } from './benchmark.config.leistungen';
import { benchmarkConfigPaPzr } from './benchmark.config.papzr';
import { benchmarkConfigPatienten } from './benchmark.config.patienten';
import { benchmarkConfigTermine } from './benchmark.config.termine';
import { benchmarkConfigTerminzeiten } from './benchmark.config.tz';
import { benchmarkConfigUmsatz } from './benchmark.config.umsatz';
import { benchmarkConfigBehandlungen } from './benchmark.config.behandlungen';
import { benchmarkConfigTBv2 } from './benchmark.config.tbv2';
import { benchmarkConfigStichworte } from './benchmark.config.stichworte';
import { benchmarkUnitShortName } from '../utils/util';

let endi = 0;
export const benchmarkPatientSeitIntervalle = [
  { name: '< 1 Monat', key: '1monat', von: 0, bis: (endi += 30) }, // 1 monat
  { name: '1 - 6 Monate', key: '6monate', von: endi, bis: (endi += 5 * 30.5) }, // 1-6 Monate
  { name: '6 - 12 Monate', key: '1jahr', von: endi, bis: (endi += 6 * 30.5) }, // 6-12 Monate
  { name: '1 - 2 Jahre', key: '2jahre', von: endi, bis: (endi += 12 * 30.5) }, // 1-2 Jahre
  { name: '2 - 5 Jahre', key: '5jahre', von: endi, bis: (endi += 3 * 12 * 30.5) }, // 2-5 Jahre
  { name: '5 - 10 Jahre', key: '10jahre', von: endi, bis: (endi += 5 * 12 * 30.5) }, // 5-10 Jahre
  { name: '> 10 Jahre', key: 'mehrAls10jahre', von: endi, bis: (endi += 50 * 12 * 30.5) }, // >10 Jahre
];

export function flattenBenchmarkConfigs(
  itemConfigs: IBenchmarkConfigItem[],
  agg: IBenchmarkConfigItem[] = [],
  path: string[] = [],
): IBenchmarkConfigItem[] {
  if (itemConfigs && itemConfigs.length > 0) {
    for (const ic of itemConfigs) {
      agg.push(ic);
      ic.path = path;
      flattenBenchmarkConfigs(ic.childs, agg, [...path, ic.name]);
    }
  }
  return agg;
}

export function extractBenchmarkConfigItemFields(item: IBenchmarkConfigItem) {
  // extract item fields
  let fields: string[] = [];
  if (item.virtual) {
    let rgx = /b(?:\.\w*)+/g;
    let match = rgx.exec(item.value!);
    while (match != null) {
      fields.push(match[0].replace('b.', ''));
      match = rgx.exec(item.value!);
    }
  } else {
    fields.push(item.key);
  }
  return fields;
}

export function getBenchmarkConfig(
  settings: ISettings,
  topStichworte: IStichwortParam[],
  env: string,
): IBenchmarkConfigItem {
  let rootItemRaw: IBenchmarkConfigItemRaw = benchmarkConfigRaw(settings, topStichworte);
  let rootItem: IBenchmarkConfigItem = {
    key: 'root',
    name: rootItemRaw.name,
    trendType: IBenchmarkConfigTrendType.none,
    isHeader: rootItemRaw.isHeader,
    isRootNode: rootItemRaw.isRootNode,
    level: 0,
    childs: [],
  };
  rootItem.childs = createChilds(rootItemRaw, rootItem, 1, env, settings);
  setItemParent(rootItem);
  // show root item
  rootItem.visible = true;
  let isFirstItem = true;
  for (const item of rootItem.childs) {
    item.visible = isFirstItem;
    item.collapsed = false;
    isFirstItem = false;
  }
  return rootItem;
}

function createChilds(
  item: IBenchmarkConfigItemRaw,
  parent: IBenchmarkConfigItem,
  level: number,
  env: string,
  settings: ISettings,
  lastunit?: BenchmarkConfigUnitType,
): IBenchmarkConfigItem[] {
  if (item && item.childs && Object.values(item.childs).length > 0) {
    let idx = 0;
    let childs: IBenchmarkConfigItem[] = [];
    for (const key in item.childs) {
      if (Object.prototype.hasOwnProperty.call(item.childs, key)) {
        let rawItem = item.childs[key];
        if (!rawItem.disabled || !rawItem.disabled.includes(env)) {
          let unit = rawItem.unit || item.unit || lastunit; // use parent unit as fallback
          let unitString = benchmarkUnitShortName(unit!);
          const newItem: IBenchmarkConfigItem = {
            key,
            trendType: rawItem.trendType || IBenchmarkConfigTrendType.biggerisbetter,
            value: rawItem.value || (rawItem.virtual ? undefined : key), // virtual field must have value otherwise undefined
            unit,
            unitString,
            name: rawItem.name,
            shortName: rawItem.shortName,
            virtual: rawItem.virtual,
            smartValue: rawItem.smartValue,
            smartUnit: rawItem.smartUnit,
            formatHint: rawItem.formatHint,
            help: rawItem.help,
            isHeader: rawItem.isHeader,
            isRootNode: rawItem.isRootNode,
            isTab: rawItem.isTab,
            noAvg: rawItem.noAvg,
            collapsed: rawItem.collapsed,
            visible: true, // everything is visible by default
            classes: rawItem.classes,
            dividedClasses: rawItem.dividedClasses,
            debug: rawItem.debug,
            hideWhenFiltered: rawItem.hideWhenFiltered,
            canFilterByPatid: rawItem.canFilterByPatid,
            color: rawItem.color,
            level,
            idx: level === 2 ? 1 : idx++, // top row should always be dark
            noHistory: rawItem.noHistory,
            beta: rawItem.beta,
            proLevel: rawItem.proLevel || BenchmarkConfigProLevelType.NEWBIE,
            excludeForPVS: rawItem.excludeForPVS,
            childs: [],
            parent,
          };
          newItem.childs = createChilds(rawItem, newItem, level + 1, env, settings, unit);
          newItem.tab = findRootParentTab(newItem);
          // check for pvs support
          if (!newItem.excludeForPVS?.includes(settings.pvs)) {
            childs.push(newItem);
          }
        }
      }
    }
    return childs;
  }
  return [];
  // return undefined;
}

export function benchmarkConfigRaw(settings: ISettings, topStichworte: IStichwortParam[]): IBenchmarkConfigItemRaw {
  return {
    name: 'KPI',
    isHeader: true,
    isRootNode: true,
    childs: {
      [BenchmarkParts.PATIENTEN]: {
        name: 'Patienten',
        isHeader: true,
        isRootNode: true,
        visible: false,
        collapsed: false,
        isTab: true,
        childs: {
          behandeltePatienten: benchmarkConfigPatienten(),
          papzr: benchmarkConfigPaPzr(),
          stichworte: benchmarkConfigStichworte(topStichworte),
        },
      },
      [BenchmarkParts.TERMINE]: {
        name: 'Termine',
        isHeader: true,
        isRootNode: true,
        visible: false,
        collapsed: false,
        isTab: true,
        childs: {
          _termine: benchmarkConfigTermine(),
          terminbuchv2: benchmarkConfigTBv2(),
          terminzeiten: benchmarkConfigTerminzeiten(),
        },
      },
      [BenchmarkParts.UMSATZ]: {
        name: 'Umsatz',
        isHeader: true,
        isRootNode: true,
        visible: false,
        collapsed: false,
        isTab: true,
        childs: {
          _umsatz: benchmarkConfigUmsatz(),
          leistungen: benchmarkConfigLeistungen(),
        },
      },
      [BenchmarkParts.HKPS]: { ...benchmarkConfigHKP(), isTab: true },
      [BenchmarkParts.BEHANDLUNGEN]: { ...benchmarkConfigBehandlungen(settings.leistungen.implantat), isTab: true },
    },
  };
}

function setItemParent(item: IBenchmarkConfigItem) {
  if (item.childs) {
    for (const c of item.childs) {
      c.parent = item;
    }
  }
}

function findRootParentTab(item: IBenchmarkConfigItem): string | undefined {
  if (item.isTab) {
    return item.key;
  }
  if (item.parent) {
    return findRootParentTab(item.parent);
  }
  return undefined;
}
