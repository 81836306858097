/* eslint-disable @typescript-eslint/unbound-method */
import Vue from 'vue';
import Vuex from 'vuex';
import { chain, toNumber, map, join } from 'lodash';
import { metricsApi } from '@/services/data';
import { createDirectStore } from 'direct-vuex';
import { lastMonth } from '../../../common-ui';
import { roseDayjs } from '../../../base';
import { ITeam, ITerminQualitaetResult, LocationsSearchVersicherungFilter, PrivatFilterTypeUI } from '../../../types';
import { viewStateStore } from '@/state/viewStateStore';

Vue.use(Vuex);

const { store, rootActionContext } = createDirectStore({
  state: () => ({
    terminQualitaet: [] as ITerminQualitaetResult[],
    loading: false,
    versicherungFilter: LocationsSearchVersicherungFilter.Alle,
    search: '',
    date: {
      startDate: roseDayjs(lastMonth[0]).format('YYYY-MM-DD'),
      endDate: roseDayjs(lastMonth[1]).format('YYYY-MM-DD'),
    },
    privatFilter: PrivatFilterTypeUI.Alle as PrivatFilterTypeUI,
    teamSelected: null as null | ITeam,
    leistungserbringerSelected: null as null | ITeam,
    error: undefined as undefined | string,
  }),
  getters: {
    terminQualitaet(state) {
      return state.terminQualitaet;
    },
    loading(state) {
      return state.loading;
    },
    date(state) {
      return state.date;
    },
    teamSelected(state) {
      return state.teamSelected;
    },
    privatFilter(state) {
      return state.privatFilter;
    },
    leistungserbringerSelected(state) {
      return state.leistungserbringerSelected;
    },
    search(state) {
      return state.search;
    },
    error(state) {
      return state.error;
    },
  },
  mutations: {
    setTerminQualitaet(state, terminQualitaet: ITerminQualitaetResult[]) {
      state.terminQualitaet = terminQualitaet;
    },
    setLoading(state, bool: boolean) {
      state.loading = bool;
    },
    setDate(state, date) {
      state.date = date;
    },
    setSearch(state, input: string) {
      state.search = input;
    },
    setError(state, error) {
      state.error = error;
    },
    setPrivatFilter(state, pf: PrivatFilterTypeUI) {
      state.privatFilter = pf;
    },
    setTeamSelected(state, team) {
      state.teamSelected = team;
    },
    setLeistungserbringerSelected(state, team) {
      state.leistungserbringerSelected = team;
    },
  },
  actions: {
    async getData(context) {
      const { state, commit } = rootActionContext(context);
      try {
        commit.setLoading(true);
        commit.setError(null);
        await viewStateStore.dispatch.addToViewState({
          search: state.search,
          von: state.date?.startDate,
          bis: state.date?.endDate,
          team: state.teamSelected?.id,
          leb: state.leistungserbringerSelected?.id,
          privat: state.privatFilter,
        });
        const tq = await metricsApi.termine.getTerminqualitaet(
          state.date?.startDate,
          state.date?.endDate,
          state.privatFilter,
          state.teamSelected?.id ? join(state.teamSelected?.mitglieder) : undefined,
          state.leistungserbringerSelected?.id ? join(state.leistungserbringerSelected?.mitglieder) : undefined,
        );

        // extend data
        type TQ = keyof ITerminQualitaetResult;
        const params: TQ[] = [
          'anteil',
          'termindaueranteil',
          'honorar',
          'honorarbema',
          'honorargoz',
          'mitpassendemfolgetermin',
        ];
        const normalizedMaximums: { [key: string]: number } = {};
        for (const p of params) {
          normalizedMaximums[p] =
            chain(tq)
              .map(x => toNumber(x[p]))
              .maxBy()
              .value() || 0.1;
        }
        const tqExtended: ITerminQualitaetResult[] = map(tq, x => {
          const tqe: any = { ...x };
          for (const p in normalizedMaximums) {
            if (Object.prototype.hasOwnProperty.call(normalizedMaximums, p)) {
              const m = normalizedMaximums[p];
              tqe[p + 'normalized'] = (tqe[p] / m) * 100;
            }
          }
          return tqe;
        });

        commit.setTerminQualitaet(tqExtended);
        commit.setLoading(false);
      } catch (err: any) {
        console.error(err);
        commit.setError(err.message);
      } finally {
        commit.setLoading(false);
      }
    },
    setSearch(context, input: string) {
      const { commit } = rootActionContext(context);
      commit.setSearch(input);
      void viewStateStore.dispatch.addToViewState({
        search: context.state.search,
      });
    },
    async setPrivatFilter(context, pf: PrivatFilterTypeUI) {
      const { dispatch, commit } = rootActionContext(context);
      commit.setPrivatFilter(pf);
      await dispatch.getData();
    },
    async setDate(context, data: { startDate: string; endDate: string }) {
      const { dispatch, commit } = rootActionContext(context);
      commit.setDate(data);
      await dispatch.getData();
    },
    async setTeamSelected(context, team: ITeam) {
      const { dispatch, commit } = rootActionContext(context);
      commit.setTeamSelected(team);
      await dispatch.getData();
    },
    async setLeistungserbringerSelected(context, team: ITeam) {
      const { dispatch, commit } = rootActionContext(context);
      commit.setLeistungserbringerSelected(team);
      await dispatch.getData();
    },
  },
});

export const terminQualitaetStore = store;
