import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.starts-with.js";
import { widgetTypeToComponent, widgetTypeToDefaultConfig, widgetTypeToTitle } from '@/components/widgets/widgetConfig';
import { kpiStore } from '@/state/kpiStore';
import { cloneDeep } from 'lodash';
import { defineComponent } from 'vue';
import { DashboardWidgetType, heuteAlarmeKeys } from '../../../../types';
var availableKpis = [{
  name: 'Umsatz',
  value: '412€'
}, {
  name: 'Neupatienten',
  value: '32'
}, {
  name: 'Score',
  value: '74%'
}];
export default defineComponent({
  props: {
    widgetConfig: {
      type: Object,
      required: true
    }
  },
  components: {},
  data: function data() {
    return {
      editingWidgetConfig: {},
      showDialog: true,
      widgetTypeToComponent: widgetTypeToComponent,
      DashboardWidgetType: DashboardWidgetType,
      availableKpis: availableKpis,
      widgetTypeToTitle: widgetTypeToTitle,
      heuteAlarmKeys: heuteAlarmeKeys
    };
  },
  computed: {
    kpiScope: function kpiScope() {
      return this.editingWidgetConfig.widgetSettings.kpiScope;
    },
    kpiDefinitions: function kpiDefinitions() {
      var _this = this;

      var kpis = kpiStore.getters.kpiDefinitions;

      if (this.kpiScope) {
        return kpis.filter(function (kpi) {
          return kpi.kpiKey.startsWith(_this.kpiScope);
        });
      }

      return kpis;
    },
    widgetSettings: function widgetSettings() {
      return this.editingWidgetConfig.widgetSettings;
    },
    benchmarkKpiTree: function benchmarkKpiTree() {
      return kpiStore.getters.benchmarkKpiTree.childs;
    }
  },
  mounted: function mounted() {
    this.editingWidgetConfig = cloneDeep(Object.assign(Object.assign({}, this.widgetConfig), {
      widgetSettings: this.widgetConfig.widgetSettings || widgetTypeToDefaultConfig[this.widgetConfig.widgetType]
    }));
  },
  methods: {
    setActive: function setActive(node) {
      console.log('setActive', node);
      this.editingWidgetConfig.widgetSettings.kpiKey = node[0];
    },
    close: function close() {
      this.$emit('cancel'); // this.showDialog = false;
    },
    save: function save() {
      this.$emit('saveEdit', this.editingWidgetConfig);
    }
  }
});