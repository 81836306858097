/* eslint-disable @typescript-eslint/unbound-method */
import { metricsApi } from '@/services/data';
import { createDirectStore } from 'direct-vuex';
import Vue from 'vue';
import Vuex from 'vuex';
import { lastMonth } from '../../../common-ui';
import { roseDayjs } from '../../../base';
import { ITeam, IExecutiveDashBoardResult } from '../../../types';
import { viewStateStore } from '@/state/viewStateStore';
import { join } from 'lodash';

Vue.use(Vuex);

const { store, rootActionContext } = createDirectStore({
  state: () => ({
    data: {} as IExecutiveDashBoardResult,
    loading: false,
    date: {
      startDate: roseDayjs(lastMonth[0]).format('YYYY-MM-DD'),
      endDate: roseDayjs(lastMonth[1]).format('YYYY-MM-DD'),
    },
    teamSelected: null as null | ITeam,
    error: undefined as undefined | string,
  }),
  getters: {
    executiveData(state) {
      return state.data;
    },
    loading(state) {
      return state.loading;
    },
    date(state) {
      return state.date;
    },
    teamSelected(state) {
      return state.teamSelected;
    },
    error(state) {
      return state.error;
    },
  },
  mutations: {
    setExecutiveData(state, data: IExecutiveDashBoardResult) {
      state.data = data;
    },
    setLoading(state, bool: boolean) {
      state.loading = bool;
    },
    setDate(state, date) {
      state.date = date;
    },
    setError(state, error) {
      state.error = error;
    },
    setTeamSelected(state, team) {
      state.teamSelected = team;
    },
  },
  actions: {
    async getExecutiveData(context) {
      const { state, commit } = rootActionContext(context);
      try {
        commit.setLoading(true);
        commit.setError(null);
        await viewStateStore.dispatch.addToViewState({
          von: state.date?.startDate,
          bis: state.date?.endDate,
          team: state.teamSelected?.id,
        });
        const dd = await metricsApi.dashboards.getExecutiveDashbard(
          state.date?.startDate,
          state.date?.endDate,
          state.teamSelected?.id ? join(state.teamSelected?.mitglieder) : undefined,
        );

        commit.setExecutiveData(dd);
        commit.setLoading(false);
      } catch (err: any) {
        console.error(err);
        commit.setError(err.message);
      } finally {
        commit.setLoading(false);
      }
    },
    async setDate(context, data: { startDate: string; endDate: string }) {
      const { dispatch, commit } = rootActionContext(context);
      commit.setDate(data);
      await dispatch.getExecutiveData();
    },
    async setTeamSelected(context, team: ITeam) {
      const { dispatch, commit } = rootActionContext(context);
      commit.setTeamSelected(team);
      await dispatch.getExecutiveData();
    },
  },
});

export const dashboardsStore = store;
